import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faYoutube, faFacebookSquare, faSpotify, faTiktok } from '@fortawesome/free-brands-svg-icons'

const SocialBar = () =>
  <div className='w-full bg-black flex justify-between px-3 py-1'>
    <a className="w-full block text-center text-3xl text-white" href="https://www.instagram.com/itsniiva/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
    <a className="w-full block text-center text-3xl text-white" href="https://www.tiktok.com/@itsniiva" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} /></a>
    <a className="w-full block text-center text-3xl text-white" href="https://open.spotify.com/artist/0viMvQMmt8vK3TO2TAkbz1?si=59qewDT6QEu39BH2dPhi_A" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faSpotify} /></a>
    <a className="w-full block text-center text-3xl text-white" href="https://www.youtube.com/NiivaOfficial" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
    <a className="w-full block text-center text-3xl text-white" href="http://www.facebook.com/itsniiva" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
    <a className="w-full block text-center text-3xl text-white" href="http://www.twitter.com/itsniiva" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
  </div>

export default SocialBar