import React, { useRef } from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Switch, Route, useLocation } from "react-router-dom"
import Watch from '../Watch'
import Listen from '../Listen'
import About from '../About'
import Follow from '../Follow'
import Shop from '../Shop'
// import Index from '../Index'
import './animation.css'

const AnimationContainer = () => {
  let location = useLocation()
  const nodeRef = useRef(null)
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="slide"
        timeout={400}>
        <Switch location={location}>
          {/* <Route exact path="/" children={<Index />} /> */}
          <Route path="/watch" component={Watch}  />
          <Route path="/listen" component={Listen} />
          <Route path="/about" component={About} />
          <Route path="/follow" component={Follow} />
          <Route path="/shop" component={Shop} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default AnimationContainer